var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ยี่ห้อ: "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.brandId.$error,
                        },attrs:{"label":"nameEn","options":_vm.rowsBrand,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.brandId),callback:function ($$v) {_vm.brandId=$$v},expression:"brandId"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.brandId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.brandId.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" รหัสรุ่นสินค้า "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelCode),expression:"modelCode"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.modelCode.$error,
                        },attrs:{"type":"text","placeholder":"รหัสรุ่นสินค้า"},domProps:{"value":(_vm.modelCode)},on:{"input":function($event){if($event.target.composing)return;_vm.modelCode=$event.target.value}}}),(_vm.submitform && _vm.$v.modelCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.modelCode.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ชื่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameTh.$error,
                        },model:{value:(_vm.nameTh),callback:function ($$v) {_vm.nameTh=$$v},expression:"nameTh"}}),(_vm.submitform && _vm.$v.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameEn.$error,
                        },model:{value:(_vm.nameEn),callback:function ($$v) {_vm.nameEn=$$v},expression:"nameEn"}}),(_vm.submitform && _vm.$v.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อย่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.shortNameTh.$error,
                        },model:{value:(_vm.shortNameTh),callback:function ($$v) {_vm.shortNameTh=$$v},expression:"shortNameTh"}}),(_vm.submitform && _vm.$v.shortNameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.shortNameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อย่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.shortNameEn.$error,
                        },model:{value:(_vm.shortNameEn),callback:function ($$v) {_vm.shortNameEn=$$v},expression:"shortNameEn"}}),(_vm.submitform && _vm.$v.shortNameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.shortNameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('br'),_c('hr'),_c('h5',[_vm._v("รายละเอียด")]),_c('div',{staticClass:"col-md-3"},[_vm._v(" ประเภทเกียร์ : "),_c('multiselect',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.transmission.$error,
                      },attrs:{"options":_vm.transmissionOptions,"label":"nameTh"},model:{value:(_vm.transmission),callback:function ($$v) {_vm.transmission=$$v},expression:"transmission"}}),(_vm.submitform && _vm.$v.transmission.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.transmission.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-md-3"},[_vm._v(" ประเภทน้ำมันที่ใช้ : "),_c('multiselect',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.fuel.$error,
                      },attrs:{"options":_vm.fuelOptions,"label":"nameTh"},model:{value:(_vm.fuel),callback:function ($$v) {_vm.fuel=$$v},expression:"fuel"}}),(_vm.submitform && _vm.$v.fuel.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.fuel.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-3"}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"validation"}},[_vm._v("เครื่องยนต์")]),_c('b-form-textarea',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.engine.$error,
                        },attrs:{"id":"textarea","placeholder":"รายละเอียดเครื่องยนต์","rows":"3","max-rows":"6"},model:{value:(_vm.engine),callback:function ($$v) {_vm.engine=$$v},expression:"engine"}}),(_vm.submitform && _vm.$v.engine.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.engine.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ปริมาตรกระบอกสูบ(ซีซี) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.cylinderVolumePump.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.cylinderVolumePump),callback:function ($$v) {_vm.cylinderVolumePump=$$v},expression:"cylinderVolumePump"}}),(_vm.submitform && _vm.$v.cylinderVolumePump.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.cylinderVolumePump.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ความกว้างกระบอกสูบ x ช่วงชัก(มม.): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.cylinderWidthSeizure.$error,
                        },attrs:{"placeholder":"เช่น 52.4 ม.ม. x 57.9 ม.ม."},model:{value:(_vm.cylinderWidthSeizure),callback:function ($$v) {_vm.cylinderWidthSeizure=$$v},expression:"cylinderWidthSeizure"}}),(_vm.submitform && _vm.$v.cylinderWidthSeizure.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.cylinderWidthSeizure.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" อัตราส่วนแรงอัด : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.compressionRatio.$error,
                        },attrs:{"placeholder":"เช่น 11.0 : 1"},model:{value:(_vm.compressionRatio),callback:function ($$v) {_vm.compressionRatio=$$v},expression:"compressionRatio"}}),(_vm.submitform && _vm.$v.compressionRatio.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.compressionRatio.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบคลัทช์ : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.clutchSystem.$error,
                        },attrs:{"placeholder":"เช่น คลัทช์แห้งอัตโนมัติแบบแรงเหวี่ยง"},model:{value:(_vm.clutchSystem),callback:function ($$v) {_vm.clutchSystem=$$v},expression:"clutchSystem"}}),(_vm.submitform && _vm.$v.clutchSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.clutchSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบส่งกำลัง (ระบบเกียร์) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.transmissionSystem.$error,
                        },attrs:{"placeholder":"เช่น อัตโนมัติ แบบ V-MATIC"},model:{value:(_vm.transmissionSystem),callback:function ($$v) {_vm.transmissionSystem=$$v},expression:"transmissionSystem"}}),(_vm.submitform && _vm.$v.transmissionSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.transmissionSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบจุดระเบิด : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.ignitionSystem.$error,
                        },attrs:{"maxlength":"150","placeholder":"เช่น FULL TRANSISTORIZED"},model:{value:(_vm.ignitionSystem),callback:function ($$v) {_vm.ignitionSystem=$$v},expression:"ignitionSystem"}}),(_vm.submitform && _vm.$v.ignitionSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.ignitionSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบการติดเครื่องยนต์ : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.engineSystem.$error,
                        },attrs:{"maxlength":"100","placeholder":"เช่น สตาร์ทมือ"},model:{value:(_vm.engineSystem),callback:function ($$v) {_vm.engineSystem=$$v},expression:"engineSystem"}}),(_vm.submitform && _vm.$v.engineSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.engineSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" มุมคาสเตอร์ / ระยะเทรล (°'/)(มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.casterCorners.$error,
                        },attrs:{"placeholder":"เช่น 26° 30' /75"},model:{value:(_vm.casterCorners),callback:function ($$v) {_vm.casterCorners=$$v},expression:"casterCorners"}}),(_vm.submitform && _vm.$v.casterCorners.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.casterCorners.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" กว้าง (มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.width.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.width),callback:function ($$v) {_vm.width=$$v},expression:"width"}}),(_vm.submitform && _vm.$v.width.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.width.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ยาว (มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.length.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.length),callback:function ($$v) {_vm.length=$$v},expression:"length"}}),(_vm.submitform && _vm.$v.length.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.length.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" สูง (มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.height.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.height),callback:function ($$v) {_vm.height=$$v},expression:"height"}}),(_vm.submitform && _vm.$v.height.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.height.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระยะห่างช่วงล้อ (มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.wheelSpacing.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.wheelSpacing),callback:function ($$v) {_vm.wheelSpacing=$$v},expression:"wheelSpacing"}}),(_vm.submitform && _vm.$v.wheelSpacing.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.wheelSpacing.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระยะห่างจากพื้น (มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.distanceBetweenFloor.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.distanceBetweenFloor),callback:function ($$v) {_vm.distanceBetweenFloor=$$v},expression:"distanceBetweenFloor"}}),(_vm.submitform && _vm.$v.distanceBetweenFloor.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.distanceBetweenFloor.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ความสูงของเบาะ (มม.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.upholsteryHeight.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.upholsteryHeight),callback:function ($$v) {_vm.upholsteryHeight=$$v},expression:"upholsteryHeight"}}),(_vm.submitform && _vm.$v.upholsteryHeight.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.upholsteryHeight.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ความจุน้ำมันเชื้อเพลิง(ลิตร) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.oilFuelCapacity.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.oilFuelCapacity),callback:function ($$v) {_vm.oilFuelCapacity=$$v},expression:"oilFuelCapacity"}}),(_vm.submitform && _vm.$v.oilFuelCapacity.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.oilFuelCapacity.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" เฟรม : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.frame.$error,
                        },attrs:{"placeholder":"เช่น อันเดอร์โบน"},model:{value:(_vm.frame),callback:function ($$v) {_vm.frame=$$v},expression:"frame"}}),(_vm.submitform && _vm.$v.frame.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.frame.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบกันสะเทือน (หน้า) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.frontPreventQuakeSystem.$error,
                        },attrs:{"maxlength":"100","placeholder":"เช่น เทเลสโคปิค"},model:{value:(_vm.frontPreventQuakeSystem),callback:function ($$v) {_vm.frontPreventQuakeSystem=$$v},expression:"frontPreventQuakeSystem"}}),(_vm.submitform && _vm.$v.frontPreventQuakeSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.frontPreventQuakeSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบกันสะเทือน (หลัง) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.backPreventQuakeSystem.$error,
                        },attrs:{"maxlength":"100","placeholder":"เช่น เทเลสโคปิค"},model:{value:(_vm.backPreventQuakeSystem),callback:function ($$v) {_vm.backPreventQuakeSystem=$$v},expression:"backPreventQuakeSystem"}}),(_vm.submitform && _vm.$v.backPreventQuakeSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.backPreventQuakeSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" น้ำหนักสุทธิ (กก.) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.netWeight.$error,
                        },attrs:{"type":"number","step":"0.01"},model:{value:(_vm.netWeight),callback:function ($$v) {_vm.netWeight=$$v},expression:"netWeight"}}),(_vm.submitform && _vm.$v.netWeight.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.netWeight.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบห้ามล้อ (หน้า) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.frontBrakeSystem.$error,
                        },attrs:{"maxlength":"150","placeholder":"เช่น ไฮดรอลิก ดิสก์เบรก"},model:{value:(_vm.frontBrakeSystem),callback:function ($$v) {_vm.frontBrakeSystem=$$v},expression:"frontBrakeSystem"}}),(_vm.submitform && _vm.$v.frontBrakeSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.frontBrakeSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ระบบห้ามล้อ (หลัง) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.backBrakeSystem.$error,
                        },attrs:{"maxlength":"150","placeholder":"เช่น ดรัมเบรก"},model:{value:(_vm.backBrakeSystem),callback:function ($$v) {_vm.backBrakeSystem=$$v},expression:"backBrakeSystem"}}),(_vm.submitform && _vm.$v.backBrakeSystem.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.backBrakeSystem.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ล้อ : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.wheel.$error,
                        },attrs:{"placeholder":"เช่น ล้อแม็ก"},model:{value:(_vm.wheel),callback:function ($$v) {_vm.wheel=$$v},expression:"wheel"}}),(_vm.submitform && _vm.$v.wheel.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.wheel.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ขนาดยาง (หน้า) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.frontTireSize.$error,
                        },attrs:{"placeholder":"เช่น 90/90-12 44J"},model:{value:(_vm.frontTireSize),callback:function ($$v) {_vm.frontTireSize=$$v},expression:"frontTireSize"}}),(_vm.submitform && _vm.$v.frontTireSize.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.frontTireSize.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ขนาดยาง (หลัง) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.backTireSize.$error,
                        },attrs:{"placeholder":"เช่น 100/90-10 56J"},model:{value:(_vm.backTireSize),callback:function ($$v) {_vm.backTireSize=$$v},expression:"backTireSize"}}),(_vm.submitform && _vm.$v.backTireSize.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.backTireSize.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" น้ำมันเชื้อเพลิง : "),_c('b-form-textarea',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.oilFuel.$error,
                        },attrs:{"id":"textarea","placeholder":"รายละเอียดน้ำมันเชื้อเพลิง","rows":"3","max-rows":"6"},model:{value:(_vm.oilFuel),callback:function ($$v) {_vm.oilFuel=$$v},expression:"oilFuel"}}),(_vm.submitform && _vm.$v.oilFuel.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.oilFuel.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("กลุ่มรถ: "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.modelGroup.$error,
                        },attrs:{"label":"nameTh","options":_vm.modelGroupOptions,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.modelGroup),callback:function ($$v) {_vm.modelGroup=$$v},expression:"modelGroup"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.modelGroup.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.modelGroup.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v(" ประเภทรถ : "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.vehicleType.$error,
                        },attrs:{"label":"nameTh","options":_vm.vehicleTypeOption,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.vehicleType),callback:function ($$v) {_vm.vehicleType=$$v},expression:"vehicleType"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.vehicleType.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.vehicleType.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" อัตราการทดเกียร์ : "),_c('b-form-textarea',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.gearRatio.$error,
                        },attrs:{"id":"textarea","placeholder":"รายละเอียดอัตราการทดเกียร์","rows":"3","max-rows":"6"},model:{value:(_vm.gearRatio),callback:function ($$v) {_vm.gearRatio=$$v},expression:"gearRatio"}}),(_vm.submitform && _vm.$v.gearRatio.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.gearRatio.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.pgmfi)},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.pgmfi.$error,
                      },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.pgmfi),callback:function ($$v) {_vm.pgmfi=$$v},expression:"pgmfi"}},[_c('img',{attrs:{"src":require("../d-vehicle-model/img/pgmfi.png")}})]),(_vm.submitform && _vm.$v.pgmfi.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.pgmfi.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.gsp)},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.gsp.$error,
                      },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.gsp),callback:function ($$v) {_vm.gsp=$$v},expression:"gsp"}},[_c('img',{attrs:{"src":require("../d-vehicle-model/img/gsp.png"),"alt":""}})]),(_vm.submitform && _vm.$v.gsp.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.gsp.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.combiBrake)},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.combiBrake.$error,
                      },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.combiBrake),callback:function ($$v) {_vm.combiBrake=$$v},expression:"combiBrake"}},[_c('img',{attrs:{"src":require("../d-vehicle-model/img/combibrake.png"),"alt":""}})]),(_vm.submitform && _vm.$v.combiBrake.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.combiBrake.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.smartTechnology)},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.smartTechnology.$error,
                      },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.smartTechnology),callback:function ($$v) {_vm.smartTechnology=$$v},expression:"smartTechnology"}},[_c('img',{attrs:{"src":require("../d-vehicle-model/img/smartTexhnology.png"),"alt":""}})]),(_vm.submitform && _vm.$v.smartTechnology.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.smartTechnology.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.em6)},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.em6.$error,
                        },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.em6),callback:function ($$v) {_vm.em6=$$v},expression:"em6"}},[_vm._v("em6")]),(_vm.submitform && _vm.$v.em6.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.em6.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.em7)},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.em7.$error,
                        },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.em7),callback:function ($$v) {_vm.em7=$$v},expression:"em7"}},[_c('img',{attrs:{"src":require("../d-vehicle-model/img/em7.png"),"alt":""}})]),(_vm.submitform && _vm.$v.em7.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.em7.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicleType.vehicleTypeId == 'MC'),expression:"vehicleType.vehicleTypeId == 'MC'"}],class:_vm.checkSelected(_vm.idlingStop)},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.idlingStop.$error,
                        },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.idlingStop),callback:function ($$v) {_vm.idlingStop=$$v},expression:"idlingStop"}},[_c('img',{attrs:{"src":require("../d-vehicle-model/img/idlingStop.png"),"alt":""}})]),(_vm.submitform && _vm.$v.idlingStop.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.idlingStop.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{staticClass:"mb-3",class:{
                            'is-invalid':
                              _vm.submitform && _vm.$v.manageBranch.$error,
                          },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.manageBranch),callback:function ($$v) {_vm.manageBranch=$$v},expression:"manageBranch"}},[_vm._v("ส่งข้อมูลไปทุกสาขา")]),(_vm.submitform && _vm.$v.manageBranch.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.manageBranch.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"success"},on:{"click":_vm.tooltipForm}},[_vm._v(" บันทึก ")])],1)])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }