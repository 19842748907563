<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มรุ่นสินค้า",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มรุ่นสินค้า",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ข้อมูลหลักผู้แทนจำหน่าย",
        },
        {
          text: "รุ่นสินค้า",
          href: "/d-vehicle-model",
        },
        {
          text: "เพิ่มรุ่นสินค้า",
          active: true,
        },
      ],
      rowsBrand: [],
      brandId: "",
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,
      userBranchId: "",
      branchId: "",
      modelCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",
      transmissionOptions: [
        {
          tmId: "AT",
          nameTh: "อัตโนมัติ",
        },
        {
          tmId: "MT",
          nameTh: "ธรรมดา",
        },
      ],
      transmission: "",

      fuelOptions: [
        {
          fuelId: "S",
          nameTh: "เบนซิน",
        },
        {
          fuelId: "D",
          nameTh: "ดีเซล",
        },
      ],
      fuel: "",
      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,

      //? เพิ่มเติม
      engine: "",
      cylinderVolumePump: "",
      cylinderWidthSeizure: "",
      compressionRatio: "",
      clutchSystem: "",
      transmissionSystem: "",
      ignitionSystem: "",
      engineSystem: "",
      width: "",
      length: "",
      height: "",
      wheelSpacing: "",
      distanceBetweenFloor: "",
      upholsteryHeight: "",
      casterCorners: "",
      oilFuelCapacity: "",
      frame: "",
      frontPreventQuakeSystem: "",
      backPreventQuakeSystem: "",
      netWeight: "",
      frontBrakeSystem: "",
      backBrakeSystem: "",
      wheel: "",
      frontTireSize: "",
      backTireSize: "",
      oilFuel: "",
      pgmfi: 0,
      gsp: 0,
      combiBrake: 0,
      smartTechnology: 0,
      em6: 0,
      em7: 0,
      idlingStop: 0,
      gearRatio: "",
      modelGroup: [],
      vehicleType:'',
      vehicleTypeOption:[
        {
          vehicleTypeId: "MC",
          nameTh: "รถจักรยานยนต์"
        },
        {
          vehicleTypeId: "C",
          nameTh: "รถยนต์"

        }
        
      ],
      modelGroupOptions: [
        {
          mgId: "AT",
          nameTh: "Automatic",
        },
        {
          mgId: "FAM",
          nameTh: "Family",
        },
        {
          mgId: "LB",
          nameTh: "Leisure Bike",
        },
        {
          mgId: "SP",
          nameTh: "Sport",
        },
      ],
    };
  },
  validations: {
    modelCode: {},
    nameTh: {
      required,
    },
    nameEn: {
      required,
    },
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
    transmission: {},
    fuel: {},
    brandId: {
      required,
    },
    //? เพิ่มเติม Validate
    engine: {},
    cylinderVolumePump: {},
    cylinderWidthSeizure: {},
    compressionRatio: {},
    clutchSystem: {},
    transmissionSystem: {},
    ignitionSystem: {},
    engineSystem: {},
    width: {},
    length: {},
    height: {},
    wheelSpacing: {},
    distanceBetweenFloor: {},
    upholsteryHeight: {},
    casterCorners: {},
    oilFuelCapacity: {},
    frame: {},
    frontPreventQuakeSystem: {},
    backPreventQuakeSystem: {},
    netWeight: {},
    frontBrakeSystem: {},
    backBrakeSystem: {},
    wheel: {},
    frontTireSize: {},
    backTireSize: {},
    oilFuel: {},
    pgmfi: {},
    gsp: {},
    combiBrake: {},
    smartTechnology: {},
    em6: {},
    em7: {},
    idlingStop: {},
    gearRatio: {},
    modelGroup: { required },
    vehicleType: {
      required,
    }
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {
    this.getBrand();
  },
  methods: {
    // border border-primary rounded
    checkSelected(value) {
      let result;
      if (value == "1" || value == 1) {
        result = "col-md-4 border border-2 border-primary rounded";
      } else {
        return "col-md-4";
      }
      return result;
    },
    getBrand: function() {
      useNetw
        .get("api/d-product/vehicle-brand", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowsBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.isHidden = true;
        });
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDfi();
      }
    },
    submitDfi: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-vehicle-model/store", {
          modelCode: this.modelCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          shortNameTh: this.shortNameTh,
          shortNameEn: this.shortNameEn,
          manageBranch: this.manageBranch,
          transmission: this.transmission.tmId,
          fuel: this.fuel.fuelId,
          brandId: this.brandId.brandId,

          //? เพิ่มเติม post
          engine: this.engine,
          cylinderVolumePump: this.cylinderVolumePump,
          cylinderWidthSeizure: this.cylinderWidthSeizure,
          compressionRatio: this.compressionRatio,
          clutchSystem: this.clutchSystem,
          transmissionSystem: this.transmissionSystem,
          ignitionSystem: this.ignitionSystem,
          engineSystem: this.engineSystem,
          width: this.width,
          length: this.length,
          height: this.height,
          wheelSpacing: this.wheelSpacing,
          distanceBetweenFloor: this.distanceBetweenFloor,
          upholsteryHeight: this.upholsteryHeight,
          casterCorners: this.casterCorners,
          oilFuelCapacity: this.oilFuelCapacity,
          frame: this.frame,
          frontPreventQuakeSystem: this.frontPreventQuakeSystem,
          backPreventQuakeSystem: this.backPreventQuakeSystem,
          netWeight: this.netWeight,
          frontBrakeSystem: this.frontBrakeSystem,
          backBrakeSystem: this.backBrakeSystem,
          wheel: this.wheel,
          frontTireSize: this.frontTireSize,
          backTireSize: this.backTireSize,
          oilFuel: this.oilFuel,
          pgmfi: this.pgmfi,
          gsp: this.gsp,
          combiBrake: this.combiBrake,
          smartTechnology: this.smartTechnology,
          em6: this.em6,
          em7: this.em7,
          idlingStop: this.idlingStop,
          gearRatio: this.gearRatio,
          modelGroup: this.modelGroup.mgId,
          vehicleType: this.vehicleType.vehicleTypeId
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-vehicle-model/edit",
            params: {
              modelId: response.data.modelId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ยี่ห้อ:
                        <multiselect
                          v-model="brandId"
                          label="nameEn"
                          :options="rowsBrand"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.brandId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.brandId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.brandId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        รหัสรุ่นสินค้า <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสรุ่นสินค้า"
                          v-model="modelCode"
                          :class="{
                            'is-invalid': submitform && $v.modelCode.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.modelCode.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.modelCode.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <h5>รายละเอียด</h5>

                    <div class="col-md-3">
                      ประเภทเกียร์ :
                      <multiselect
                        v-model="transmission"
                        :options="transmissionOptions"
                        label="nameTh"
                        :class="{
                          'is-invalid': submitform && $v.transmission.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.transmission.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.transmission.required">{{
                          error
                        }}</span>
                      </div>
                    </div>

                    <div class="col-md-3">
                      ประเภทน้ำมันที่ใช้ :
                      <multiselect
                        v-model="fuel"
                        :options="fuelOptions"
                        label="nameTh"
                        :class="{
                          'is-invalid': submitform && $v.fuel.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.fuel.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.fuel.required">{{ error }}</span>
                      </div>
                    </div>

                    <div class="col-3"></div>

                    <div class="col-md-12">
                      <div class="mb-3 position-relative">
                        <label for="validation">เครื่องยนต์</label>
                        <b-form-textarea
                          id="textarea"
                          v-model="engine"
                          placeholder="รายละเอียดเครื่องยนต์"
                          rows="3"
                          max-rows="6"
                          :class="{
                            'is-invalid': submitform && $v.engine.$error,
                          }"
                        ></b-form-textarea>
                        <div
                          v-if="submitform && $v.engine.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.engine.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ปริมาตรกระบอกสูบ(ซีซี) :
                        <b-form-input
                          v-model="cylinderVolumePump"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid':
                              submitform && $v.cylinderVolumePump.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.cylinderVolumePump.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.cylinderVolumePump.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ความกว้างกระบอกสูบ x ช่วงชัก(มม.):
                        <b-form-input
                          placeholder="เช่น 52.4 ม.ม. x 57.9 ม.ม."
                          v-model="cylinderWidthSeizure"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.cylinderWidthSeizure.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.cylinderWidthSeizure.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.cylinderWidthSeizure.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        อัตราส่วนแรงอัด :
                        <b-form-input
                          placeholder="เช่น 11.0 : 1"
                          v-model="compressionRatio"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.compressionRatio.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.compressionRatio.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.compressionRatio.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-7">
                      <div class="mb-3 position-relative">
                        ระบบคลัทช์ :
                        <b-form-input
                          placeholder="เช่น คลัทช์แห้งอัตโนมัติแบบแรงเหวี่ยง"
                          v-model="clutchSystem"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.clutchSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.clutchSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.clutchSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        ระบบส่งกำลัง (ระบบเกียร์) :
                        <b-form-input
                          placeholder="เช่น อัตโนมัติ แบบ V-MATIC"
                          v-model="transmissionSystem"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.transmissionSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.transmissionSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.transmissionSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ระบบจุดระเบิด :
                        <b-form-input
                          maxlength="150"
                          placeholder="เช่น FULL TRANSISTORIZED"
                          v-model="ignitionSystem"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.ignitionSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.ignitionSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.ignitionSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ระบบการติดเครื่องยนต์ :
                        <b-form-input
                          maxlength="100"
                          placeholder="เช่น สตาร์ทมือ"
                          v-model="engineSystem"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.engineSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.engineSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.engineSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        มุมคาสเตอร์ / ระยะเทรล (°'/)(มม.) :
                        <b-form-input
                          placeholder="เช่น 26° 30' /75"
                          v-model="casterCorners"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.casterCorners.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.casterCorners.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.casterCorners.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        กว้าง (มม.) :
                        <b-form-input
                          v-model="width"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid': submitform && $v.width.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.width.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.width.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ยาว (มม.) :
                        <b-form-input
                          v-model="length"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid': submitform && $v.length.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.length.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.length.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        สูง (มม.) :
                        <b-form-input
                          v-model="height"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid': submitform && $v.height.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.height.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.height.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3"></div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ระยะห่างช่วงล้อ (มม.) :
                        <b-form-input
                          v-model="wheelSpacing"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid': submitform && $v.wheelSpacing.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.wheelSpacing.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.wheelSpacing.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ระยะห่างจากพื้น (มม.) :
                        <b-form-input
                          v-model="distanceBetweenFloor"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid':
                              submitform && $v.distanceBetweenFloor.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.distanceBetweenFloor.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.distanceBetweenFloor.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <div class="mb-3 position-relative">
                        ความสูงของเบาะ (มม.) :
                        <b-form-input
                          v-model="upholsteryHeight"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid':
                              submitform && $v.upholsteryHeight.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.upholsteryHeight.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.upholsteryHeight.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3"></div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ความจุน้ำมันเชื้อเพลิง(ลิตร) :
                        <b-form-input
                          v-model="oilFuelCapacity"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid':
                              submitform && $v.oilFuelCapacity.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.oilFuelCapacity.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.oilFuelCapacity.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        เฟรม :
                        <b-form-input
                          placeholder="เช่น อันเดอร์โบน"
                          v-model="frame"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.frame.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.frame.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.frame.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ระบบกันสะเทือน (หน้า) :
                        <b-form-input
                          maxlength="100"
                          placeholder="เช่น เทเลสโคปิค"
                          v-model="frontPreventQuakeSystem"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.frontPreventQuakeSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.frontPreventQuakeSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.frontPreventQuakeSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ระบบกันสะเทือน (หลัง) :
                        <b-form-input
                        maxlength="100"
                          placeholder="เช่น เทเลสโคปิค"
                          v-model="backPreventQuakeSystem"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.backPreventQuakeSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.backPreventQuakeSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.backPreventQuakeSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        น้ำหนักสุทธิ (กก.) :
                        <b-form-input
                          v-model="netWeight"
                          class="form-control"
                          type="number"
                          step="0.01"
                          :class="{
                            'is-invalid': submitform && $v.netWeight.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.netWeight.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.netWeight.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ระบบห้ามล้อ (หน้า) :
                        <b-form-input
                          maxlength="150"
                          placeholder="เช่น ไฮดรอลิก ดิสก์เบรก"
                          v-model="frontBrakeSystem"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.frontBrakeSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.frontBrakeSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.frontBrakeSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ระบบห้ามล้อ (หลัง) :
                        <b-form-input
                          maxlength="150"
                          placeholder="เช่น ดรัมเบรก"
                          v-model="backBrakeSystem"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.backBrakeSystem.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.backBrakeSystem.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.backBrakeSystem.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ล้อ :
                        <b-form-input
                          placeholder="เช่น ล้อแม็ก"
                          v-model="wheel"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.wheel.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.wheel.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.wheel.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ขนาดยาง (หน้า) :
                        <b-form-input
                          placeholder="เช่น 90/90-12 44J"
                          v-model="frontTireSize"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.frontTireSize.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.frontTireSize.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.frontTireSize.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ขนาดยาง (หลัง) :
                        <b-form-input
                          placeholder="เช่น 100/90-10 56J"
                          v-model="backTireSize"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.backTireSize.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.backTireSize.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.backTireSize.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="mb-3 position-relative">
                        น้ำมันเชื้อเพลิง :
                        <b-form-textarea
                          id="textarea"
                          v-model="oilFuel"
                          placeholder="รายละเอียดน้ำมันเชื้อเพลิง"
                          rows="3"
                          max-rows="6"
                          :class="{
                            'is-invalid': submitform && $v.oilFuel.$error,
                          }"
                        ></b-form-textarea>
                        <div
                          v-if="submitform && $v.oilFuel.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.oilFuel.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>กลุ่มรถ:
                        <multiselect
                          v-model="modelGroup"
                          label="nameTh"
                          :options="modelGroupOptions"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.modelGroup.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.modelGroup.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.modelGroup.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>


                      <div class="mb-3 position-relative">
                        <code> * </code> ประเภทรถ :
                        <multiselect
                          v-model="vehicleType"
                          label="nameTh"
                          :options="vehicleTypeOption"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.vehicleType.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.vehicleType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleType.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>


                      
                    </div>

                    <div class="col-md-8">
                      <div class="mb-3 position-relative">
                        อัตราการทดเกียร์ :
                        <b-form-textarea
                          id="textarea"
                          v-model="gearRatio"
                          placeholder="รายละเอียดอัตราการทดเกียร์"
                          rows="3"
                          max-rows="6"
                          :class="{
                            'is-invalid': submitform && $v.gearRatio.$error,
                          }"
                        ></b-form-textarea>
                        <div
                          v-if="submitform && $v.gearRatio.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.gearRatio.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div :class="checkSelected(pgmfi)" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <label for="">&nbsp;</label><br />
                      <b-form-checkbox
                        v-model="pgmfi"
                        value="1"
                        unchecked-value="0"
                        checked
                        plain
                        :class="{
                          'is-invalid': submitform && $v.pgmfi.$error,
                        }"
                      >
                        <img src="../d-vehicle-model/img/pgmfi.png" />
                      </b-form-checkbox>
                      <div
                        v-if="submitform && $v.pgmfi.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.pgmfi.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>

                    <div :class="checkSelected(gsp)" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <label for="">&nbsp;</label><br />
                      <b-form-checkbox
                        v-model="gsp"
                        value="1"
                        unchecked-value="0"
                        checked
                        plain
                        :class="{
                          'is-invalid': submitform && $v.gsp.$error,
                        }"
                        ><img src="../d-vehicle-model/img/gsp.png" alt=""
                      /></b-form-checkbox>

                      <div
                        v-if="submitform && $v.gsp.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.gsp.required">{{ errormessage }}</span>
                      </div>
                    </div>

                    <div :class="checkSelected(combiBrake)" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <label for="">&nbsp;</label><br />
                      <b-form-checkbox
                        v-model="combiBrake"
                        value="1"
                        unchecked-value="0"
                        checked
                        plain
                        :class="{
                          'is-invalid': submitform && $v.combiBrake.$error,
                        }"
                        ><img src="../d-vehicle-model/img/combibrake.png" alt=""
                      /></b-form-checkbox>

                      <div
                        v-if="submitform && $v.combiBrake.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.combiBrake.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>

                    <div :class="checkSelected(smartTechnology)" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <label for="">&nbsp;</label><br />
                      <b-form-checkbox
                        v-model="smartTechnology"
                        value="1"
                        unchecked-value="0"
                        checked
                        plain
                        :class="{
                          'is-invalid': submitform && $v.smartTechnology.$error,
                        }"
                        ><img
                          src="../d-vehicle-model/img/smartTexhnology.png"
                          alt=""
                      /></b-form-checkbox>

                      <div
                        v-if="submitform && $v.smartTechnology.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.smartTechnology.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>

                    <div :class="checkSelected(em6)" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="em6"
                          value="1"
                          unchecked-value="0"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.em6.$error,
                          }"
                          >em6</b-form-checkbox
                        >
                        <div
                          v-if="submitform && $v.em6.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.em6.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div :class="checkSelected(em7)" v-show="vehicleType.vehicleTypeId == 'MC'">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="em7"
                          value="1"
                          unchecked-value="0"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.em7.$error,
                          }"
                          ><img src="../d-vehicle-model/img/em7.png" alt=""
                        /></b-form-checkbox>
                        <div
                          v-if="submitform && $v.em7.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.em7.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div :class="checkSelected(idlingStop)" v-show="vehicleType.vehicleTypeId == 'MC'"> 
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="idlingStop"
                          value="1"
                          unchecked-value="0"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.idlingStop.$error,
                          }"
                          ><img
                            src="../d-vehicle-model/img/idlingStop.png"
                            alt=""
                        /></b-form-checkbox>

                        <div
                          v-if="submitform && $v.idlingStop.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.idlingStop.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div class="row">
                      <div class="col-3">
                        <div class="mb-3 position-relative">
                          <label for="">&nbsp;</label><br />
                          <b-form-checkbox
                            v-model="manageBranch"
                            value="1"
                            unchecked-value="0"
                            class="mb-3"
                            checked
                            plain
                            :class="{
                              'is-invalid':
                                submitform && $v.manageBranch.$error,
                            }"
                            >ส่งข้อมูลไปทุกสาขา</b-form-checkbox
                          >
                          <div
                            v-if="submitform && $v.manageBranch.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.manageBranch.required">{{
                              errormessage
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
